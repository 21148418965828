<template>
    <div>
        <template v-if="showContent">
            <Event v-for="(event, index) of localEvents" v-bind="event" v-bind:key="event.eventid" v-bind:nbroftickets="event.tickets.length">
                <div v-if="event.retourPossible && event.retourOptions.length > 0" class="relative pr-4">
                    <button 
                        @click="initRetour(event.id, event.name, event.startts, event.retourOptions)" 
                        class="py-2 px-4 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                            transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        {{ $t('schedule.returntickets') }}
                    </button>
                </div>

                <template v-if="event.upsellurl">
                    <span class="border"></span>

                    <div class="relative pr-4">
                        <a 
                            :href="event.upsellurl"
                            target="_blank"
                            class="inline-block py-2 px-4 tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                                transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            Extra's bestellen
                        </a>    
                    </div>
                </template>

                <span class="border"></span>

                <div class="relative pr-4">
                    <button 
                        @click="handleTickets(index, event.tickets)"
                        class="py-2 px-4 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                            transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        {{ $t('schedule.tickets') }}
                    </button>

                    <div v-if="activeDropdown === index" @click="activeDropdown = null" class="fixed inset-0 h-full w-full z-10"></div>

                    <div v-if="activeDropdown === index" class="absolute right-0 mt-2 w-60 bg-white rounded-md overflow-hidden shadow-xl z-20">
                        <a 
                            v-for="(tickets, orderid, i) in ticketsPerOrder(event.tickets)" :key="i"
                            href="#"
                            @click="handleTickets(index, tickets)"
                            class="block px-4 py-2 text-md text-gray-800 border-b hover:bg-gray-200">
                                Tickets voor bestelling {{ orderid }} ({{tickets.length}}x)
                        </a>
                    </div>
                </div>                
            </Event>
            <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
                <div slot="spinner">
                    <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
                </div>
                <div class="text-left" slot="no-more"></div>
                <div class="text-left" slot="no-results">
                    <p class="pb-4">
                        Geen tickets voor toekomstige evenementen.
                    </p>
                    <p class="pb-4">
                        <a :target="config.urlTarget" title="Bestel tickets" :href="config.salesUrl" 
                            class="py-2 px-4 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                                transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                            Bestel tickets
                        </a>
                    </p>
                </div>
            </infinite-loading>
        </template>
        <div v-if="error">
            <p>
                Het is niet gelukt de gegevens op te halen. 
            </p>
            <p>
                Probeer het later nog een keer of neem contact met ons op via 
                <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a> 
                <span v-if="config.phone">
                of {{ config.phone }}
                </span>.
            </p>
        </div>  

        <Modal 
            button="Sluiten" 
            title="Retourneren" 
            icon="spinner"
            ref="retourLoadingModal">
        </Modal>

        <ConfirmationModal 
            @execute="executeRetour" 
            @cancel="clearRetour" 
            title="Retourneren"
            confirmation="Ik weet zeker dat ik deze tickets wil retourneren."
            ref="retourChoiceModal"
            >
            <template v-if="retourResult.restitution === 0">
                <p class="my-1 sm:my-4 text-md sm:text-xl leading-relaxed">
                    Annuleer hier je gereserveerde tickets voor <b>{{ retourState.eventname }}</b> 
                    op {{ retourState.eventstartts | friendlyDate }}.
                </p>
            </template>
            <template v-else-if="retourResult.cost > 0">
                <p class="my-1 sm:my-4 text-md sm:text-xl leading-relaxed">
                    Het is mogelijk om je tickets voor 
                    <b>{{ retourState.eventname }}</b> op {{ retourState.eventstartts | friendlyDate }} te retourneren. 
                    Er wordt <b>&euro; {{ retourResult.cost | money }}</b> aan annuleringskosten gerekend.
                </p>
                <p class="my-1 sm:my-4 text-md sm:text-xl leading-relaxed">
                    <span v-if="nbrOfRetourOptions > 1">
                        Kies zelf wat er moet gebeuren met het resterende bedrag van 
                    </span>
                    <span v-else>
                        Het resterende bedrag is 
                    </span>
                    <b>&euro; {{ retourResult.restitution | money }}</b>:
                </p>
            </template>
            <template v-else>
                <p class="my-1 sm:my-4 text-blueGray-500 text-lg sm:text-xl leading-relaxed">
                    Retourneer hier je tickets voor <b>{{ retourState.eventname }}</b> 
                    op {{ retourState.eventstartts | friendlyDate }}.
                </p>
                <p class="my-1 sm:my-4 text-blueGray-500 text-lg sm:text-xl leading-relaxed">
                    <span v-if="nbrOfRetourOptions > 1">
                        Kies zelf wat er moet gebeuren met het resterende bedrag van 
                    </span>
                    <span v-else>
                        Het resterende bedrag is 
                    </span>
                    <b>&euro; {{ retourResult.restitution | money }}</b>:
                </p>
            </template>
            <div class="pl-2 sm:pl-12" v-if="retourResult.restitution > 0">
                <div class="mx-auto">
                    <div v-if="retourState.retourOptions.includes('voucher')" class="flex items-center mr-1 sm:mr-4 mb-4">
                        <input id="voucher" type="radio" name="cancellationType" value="voucher" v-model="retourState.type" class="hidden" checked />
                        <label for="voucher" class="flex items-center cursor-pointer text-lg sm:text-xl">
                            <span class="w-8 min-w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                            Ik ontvang het bedrag <span v-if="nbrOfRetourOptions > 1">graag</span> 
                            als persoonlijk tegoed
                        </label>
                    </div>
                    <div v-if="retourState.retourOptions.includes('refund') && retourResult.refundable" class="flex items-center mr-1 sm:mr-4 mb-4">
                        <input id="refund" type="radio" name="cancellationType" value="refund" v-model="retourState.type" class="hidden" />
                        <label for="refund" class="flex items-center cursor-pointer text-lg sm:text-xl">
                            <span class="w-8 min-w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                            Ik ontvang het bedrag <span v-if="nbrOfRetourOptions > 1">graag</span> 
                            terug op mijn bankrekening
                        </label>
                    </div>
                    <div v-if="retourState.retourOptions.includes('donation')" class="flex items-center mr-1 sm:mr-4 mb-4">
                        <input id="donation" type="radio" name="cancellationType" value="donation" v-model="retourState.type" class="hidden" />
                        <label for="donation" class="flex items-center cursor-pointer text-lg sm:text-xl">
                            <span class="w-8 min-w-8 h-8 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                            Ik doneer het bedrag
                        </label>
                    </div>
                </div>
            </div>
        </ConfirmationModal>

        <Modal
            title="Neem contact op met de kassa"
            ref="retourNotPossible">
            <p class="text-lg">
                Omdat je aankoop niet (volledig) met automatisch retourneerbare betaalmethoden werd betaald, kunnen we via deze weg je aanvraag om het bedrag terug te storten, niet afhandelen.
                Neem contact op met de kassa via <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a> of {{ config.phone }}.
            </p>
            <p class="text-lg" v-if="config.website">
                Check de openingstijden van de kassa via <a class="underline" target="_blank" :href="config.website">{{ config.website }}</a>.
            </p>
            <ul>
                <li v-if="config.email">E-mail: <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a></li>
                <li v-if="config.phone">Telefoon: {{ config.phone }}</li>
            </ul>
        </Modal>        

        <Modal
            @closed="refreshSchedule" 
            title="Het is gelukt" 
            icon="check-circle"
            ref="retourFinishedModal"
        >
            <p v-if="retourResult.credit > 0" class="text-lg">
                Er is <b>&euro; {{ retourResult.credit | currency }}</b> aan je persoonlijke tegoed toegevoegd.
            </p>
            <p v-else-if="retourResult.donation > 0" class="text-lg">
                <b>Je hebt &euro; {{ retourResult.donation | currency }}</b> gedoneerd. 
                Hartelijk dank!
            </p>
            <p v-else-if="retourResult.refund > 0" class="text-lg">
                Er wordt <b>&euro; {{ retourResult.refund | currency }}</b> teruggeboekt naar je bankrekening.
            </p>            
        </Modal>

        <Modal
            title="Tickets niet online beschikbaar."
            ref="notDeliveredModal">
            <p class="text-lg">
                De tickets kunnen nog niet worden gedownload, omdat de betaling en/of bestelling nog niet is afgerond. 
                Neem voor vragen contact op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>

        <Modal
            title="Tickets niet online beschikbaar."
            ref="notAllowedModal">
            <p class="text-lg">
                {{ config.tickets.notAllowed[lang] }}
                Neem voor vragen contact op via <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>

        <Modal
            @closed="refreshSchedule"
            title="Niet gelukt"
            ref="errorModal"
            icon="exclamation-circle"
        >
            <p class="text-lg">
                Het is niet gelukt om deze aanvraag te verwerken.
            </p>
            <p class="text-lg">
                Neem voor vragen contact op met <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading';
import { getTicketsUrl, retourCheck, retourExecute } from '../services/OrderService'
import Event from '../components/Event.vue'
import Modal from '../components/Modal.vue'
import ConfirmationModal from '../components/ConfirmationModal.vue'
const moment = require("moment")
const R = require("ramda")
moment.locale("nl")

const DELIVERED = 2602
const STEPSIZE = 5

const RETOUR_VOUCHER = "voucher"
const RETOUR_REFUND = "refund"
const RETOUR_DONATION = "donation"

/* Helper function */
function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
           if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href; 
                // window event not working here
            }else{
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }	
    }

    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

export default {
  name: 'Ticketmatic',
  title: 'Mijn Tickets',
  components: {
      Event,
      InfiniteLoading,
      Modal,
      ConfirmationModal,
  },
  data() {
    return {
        retourState: {
            type: null,
            eventid: null,
            eventname: "",
            eventstartts: null,
            retourOptions: [],
            checked: false,
        },
        retourResult: {
            refundable: null,
            cost: 0,
            preserveTicketfeesAmount: 0,
            credit: 0,
            donation: 0,
            restitution: 0,
        },
        activeDropdown: null,
        localEvents: [],
        counter: 0,
        error: false,
    }
  },
  computed: {
    ...mapGetters(["config", "schedule"]),
    nbrOfRetourOptions() {
        if (!this.retourOptions) {
            return 0
        }
        const nbr = this.retourOptions.length
        if (this.retourState.refundable === false && this.retourOptions.includes("refund")) {
            return (nbr - 1)
        }
        return nbr
    },
    showContent: function() {
        return (!this.error)
    }
  },
  methods: {
      reset() {
          this.initList()
          this.$store.dispatch('resetSchedule')
      },
    canChooseRetourType(options, result) {
        if (result.refundable === false && R.equals(["refund"], options)) {
            return false;
        }
        return true;
    },
    clearRetour() {
        this.retourState = {
            type: null,
            eventid: null,
            eventname: "",
            eventstartts: null,
            retourOptions: [],
            confirmed: false,
        }
        this.retourResult = {
            refundable: null,
            cost: 0,
            preserveTicketfeesAmount: 0,
            restitution: 0,
        }
    },
    initialRetourType: function(retourOptions, refundable) {
        if (retourOptions.length === 0) {
            return null;
        }
        if (retourOptions.includes(RETOUR_VOUCHER)) {
            return RETOUR_VOUCHER;
        }
        if (retourOptions.includes(RETOUR_REFUND) && refundable === true) {
            return RETOUR_REFUND;
        }
        if (retourOptions.includes(RETOUR_DONATION)) {
            return RETOUR_DONATION;
        }
        return null;
    },
    initRetour: async function (eventid, eventname, eventstartts, retourOptions) {
        this.$refs.retourLoadingModal.toggle();
        this.retourState.eventid = eventid;
        this.retourState.eventname = eventname;
        this.retourState.eventstartts = eventstartts;
        this.retourState.retourOptions = retourOptions;
        this.retourResult = {
            refundable: null,
            cost: 0,
            preserveTicketfeesAmount: 0,
            restitution: 0,
        };
        await this.checkRetour(eventid);
        this.retourState.type = this.initialRetourType(retourOptions, this.retourResult.refundable)
        this.$refs.retourLoadingModal.toggle();
        if (this.retourResult && this.canChooseRetourType(this.retourState.retourOptions, this.retourResult)) {
            this.$refs.retourChoiceModal.toggle();
        }
        else if(this.retourResult) {
            this.$refs.retourNotPossible.toggle();
        }
    },
    checkRetour: async function (eventid) {
        try {
            const result = await retourCheck(eventid);
            this.retourResult = result
        }
        catch(err) {
            this.$refs.retourChoiceModal.toggle();
            this.$refs.errorModal.toggle();
        }
    },
    executeRetour: async function () {
        try {
            this.$refs.retourLoadingModal.toggle();
            const result = await retourExecute(this.retourState);
            this.retourResult = result;
            this.$refs.retourLoadingModal.toggle();
            this.$refs.retourFinishedModal.toggle();
        } 
        catch(err) {
            this.$refs.retourLoadingModal.toggle();
            this.$refs.errorModal.toggle();
        }
    },
    initList: function () {
        this.counter = 0;
        this.localEvents = [];
    },
    toggleList: function () {
        this.initList();
    },
    refreshSchedule: async function () {
        this.clearRetour();
        await this.$store.dispatch("setSchedule");
    },
    handleTickets: async function(index, tickets) {
        this.hideDropdown();
        const orderids = R.pipe(R.pluck("orderid"), R.uniq)(tickets)
        if (orderids.length === 1) {
            const allDelivered = R.pipe(R.pluck("deliverystatus"), R.all(R.equals(DELIVERED)))(tickets)
            const allAllowed = R.pipe(R.pluck("pdfallowed"), R.all(R.equals(true)))(tickets)
            if (allDelivered && allAllowed) {
                this.downloadTickets(tickets);
            }
            else if (allDelivered === false) {
                this.$refs.notDeliveredModal.toggle();
            }            
            else if (allAllowed === false) {
                this.$refs.notAllowedModal.toggle();
            }
        }
        else if (orderids.length > 1) {
            this.showDropdown(index);
        }
    },
    ticketsPerOrder: function(tickets) {
        return R.groupBy(R.prop("orderid"), tickets)
    },
    showDropdown: function(index) {
        this.activeDropdown = index;
    },
    hideDropdown: function() {
        this.activeDropdown = null;
    },
    downloadTickets: async function(tickets) {
        const orderid = R.pipe(R.head, R.prop("orderid"))(tickets)
        const ticketids = R.pluck("id", tickets)
        try {
            var { url, error } = await getTicketsUrl(orderid, ticketids)
            if (error) {
                alert(error)
            }
            if (url) {
                download_file(url, 'tickets.pdf');
            }
        }
        catch(err) {
            this.$refs.errorModal.toggle();
        }
    },
    infiniteHandler: function($state) {
        setTimeout(() => {
            if (this.schedule.events === null) {
                return
            }
            const items = this.schedule.events.slice(this.counter, this.counter + STEPSIZE);
            this.localEvents.push(...items);
            this.counter += STEPSIZE;
            if (items.length > 0) {
                $state.loaded();
            }
            if (this.localEvents.length === this.schedule.events.length) {
                $state.complete();
            }
        }, 500);
    },
  },
  filters: {
    friendlyDate: function (date) {
        return moment(date).format('D MMMM YYYY')
    },
    money: function(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    },
  },
  watch: {
      schedule: {
          handler(val, oldVal) {
              if (!this.schedule) {
                  return;
              }
              if (!this.schedule.events) {
                  return;
              }
              if (R.equals(val, oldVal)) {
                  return;
              }
              this.initList();
              if (this.$refs.InfiniteLoading) {
                  this.$refs.InfiniteLoading.stateChanger.reset();
              }          
          },
          deep: true,
      },
  },  
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Schedule",
    })

    if (this.schedule && this.schedule.events) {
        return; // do not refresh every mount
    }

    try {
        await this.$store.dispatch('setSchedule')
    } catch (ex) {
        this.error = true;
        this.$store.dispatch('resetSchedule')
    }
  },
}
</script>